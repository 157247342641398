const appsListRef = document.querySelector('.apps__list');

appsListRef.addEventListener('click', onListClick);

function onListClick(e) {
  if (e.target.classList.contains('app__btn-details')) {
    const element = e.target?.closest('.app');
    const elementExtraContent = element?.querySelector('.app__desc');
    const elementBtn = element?.querySelector('.app__btn-details');

    if (elementExtraContent.style.maxHeight) elementExtraContent.style.maxHeight = null;
    else elementExtraContent.style.maxHeight = elementExtraContent.scrollHeight + 'px';

    const expanded = elementBtn.getAttribute('aria-expanded') === 'true' || false;
    elementBtn.setAttribute('aria-expanded', !expanded);
    elementBtn?.classList.toggle('isActive');
  }
}
