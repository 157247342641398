new Swiper('.apps-swiper--1', {
  navigation: {
    prevEl: '.apps-swiper--1 .apps-swiper-btn-prev',
    nextEl: '.apps-swiper--1 .apps-swiper-btn-next',
  },
  pagination: {
    el: '.apps-swiper--1 .apps-swiper-pagination',
    clickable: true,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 28,
  speed: 800,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  loop: true,
  freeMode: true,
  // autoHeight: true,
});

new Swiper('.apps-swiper--2', {
  navigation: {
    prevEl: '.apps-swiper--2 .apps-swiper-btn-prev',
    nextEl: '.apps-swiper--2 .apps-swiper-btn-next',
  },
  pagination: {
    el: '.apps-swiper--2 .apps-swiper-pagination',
    clickable: true,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 28,
  speed: 800,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  loop: true,
  freeMode: true,
  // autoHeight: true,
});

new Swiper('.apps-swiper--3', {
  navigation: {
    prevEl: '.apps-swiper--3 .apps-swiper-btn-prev',
    nextEl: '.apps-swiper--3 .apps-swiper-btn-next',
  },
  pagination: {
    el: '.apps-swiper--3 .apps-swiper-pagination',
    clickable: true,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 28,
  speed: 800,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  loop: true,
  freeMode: true,
  // autoHeight: true,
});